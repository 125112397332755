import React from "react";
import { SVGprops } from "../../interfaces";

export const ShareIcon = (props: SVGprops) => {
  const { size, color = "currentColor", fill = "none" } = props;
  return (
    <svg
      width={size.toString()}
      height={size.toString()}
      viewBox="0 0 32 32"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.7922 7.65595C22.1074 7.20244 22.7306 7.0903 23.1841 7.4055C26.0807 9.41866 28.0815 12.6213 28.4874 16.3175C28.5477 16.8665 28.1515 17.3604 27.6025 17.4207C27.0535 17.481 26.5596 17.0848 26.4993 16.5358C26.1585 13.432 24.4794 10.7413 22.0427 9.04781C21.5892 8.73262 21.477 8.10946 21.7922 7.65595Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2698 7.71596C10.5886 8.16691 10.4816 8.79095 10.0306 9.10981C7.61825 10.8155 5.96432 13.5056 5.64808 16.5951C5.59184 17.1445 5.10086 17.5443 4.55145 17.4881C4.00203 17.4319 3.60223 16.9409 3.65847 16.3915C4.03556 12.7076 6.0083 9.50441 8.87595 7.47678C9.32689 7.15793 9.95094 7.26502 10.2698 7.71596Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0287 27.4667C10.2791 26.9744 10.8811 26.7783 11.3734 27.0287C12.7809 27.7446 14.3857 28.1467 16.08 28.1467C17.715 28.1467 19.2454 27.7812 20.6157 27.1142C21.1123 26.8725 21.7108 27.0791 21.9525 27.5757C22.1942 28.0723 21.9876 28.6708 21.491 28.9125C19.848 29.7122 18.0183 30.1467 16.08 30.1467C14.0676 30.1467 12.1524 29.6688 10.4667 28.8113C9.97439 28.561 9.7783 27.9589 10.0287 27.4667Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.08 3.85339C14.5851 3.85339 13.3733 5.06521 13.3733 6.56006C13.3733 8.05491 14.5851 9.26673 16.08 9.26673C17.5748 9.26673 18.7866 8.05491 18.7866 6.56006C18.7866 5.06521 17.5748 3.85339 16.08 3.85339ZM11.3733 6.56006C11.3733 3.96064 13.4805 1.85339 16.08 1.85339C18.6794 1.85339 20.7866 3.96064 20.7866 6.56006C20.7866 9.15948 18.6794 11.2667 16.08 11.2667C13.4805 11.2667 11.3733 9.15948 11.3733 6.56006Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.43994 20.1466C4.94509 20.1466 3.73328 21.3584 3.73328 22.8533C3.73328 24.3481 4.94509 25.5599 6.43994 25.5599C7.93479 25.5599 9.14661 24.3481 9.14661 22.8533C9.14661 21.3584 7.93479 20.1466 6.43994 20.1466ZM1.73328 22.8533C1.73328 20.2539 3.84052 18.1466 6.43994 18.1466C9.03936 18.1466 11.1466 20.2539 11.1466 22.8533C11.1466 25.4527 9.03936 27.5599 6.43994 27.5599C3.84052 27.5599 1.73328 25.4527 1.73328 22.8533Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.5599 20.1466C24.0651 20.1466 22.8533 21.3584 22.8533 22.8533C22.8533 24.3481 24.0651 25.5599 25.5599 25.5599C27.0548 25.5599 28.2666 24.3481 28.2666 22.8533C28.2666 21.3584 27.0548 20.1466 25.5599 20.1466ZM20.8533 22.8533C20.8533 20.2539 22.9605 18.1466 25.5599 18.1466C28.1594 18.1466 30.2666 20.2539 30.2666 22.8533C30.2666 25.4527 28.1594 27.5599 25.5599 27.5599C22.9605 27.5599 20.8533 25.4527 20.8533 22.8533Z"
        fill={color}
      />
    </svg>
  );
};
