import React from "react";
import { classesOf } from "../../utils";
import { SVGprops } from "../../interfaces";

export const ChatQuestionIcon = (props: SVGprops) => {
  const {
    size,
    color = "currentColor",
    fill = "none",
    direction = "up",
  } = props;

  const arrowClasses = classesOf(
    "origin-center",
    direction === "down" && "rotate-180",
    direction === "right" && "rotate-90",
    direction === "left" && "-rotate-90",
  );

  return (
    <svg
      className={arrowClasses}
      width={size.toString()}
      height={size.toString()}
      viewBox="0 0 16 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.3335 14.8802C5.14683 14.8802 4.95348 14.8336 4.78015 14.7403C4.40015 14.5403 4.16683 14.1402 4.16683 13.7136V12.767C2.1535 12.5603 0.833496 11.0802 0.833496 8.96025V4.96029C0.833496 2.66695 2.3735 1.12695 4.66683 1.12695H11.3335C13.6268 1.12695 15.1668 2.66695 15.1668 4.96029V8.96025C15.1668 11.2536 13.6268 12.7936 11.3335 12.7936H8.82015L5.98014 14.687C5.78681 14.8136 5.56016 14.8802 5.3335 14.8802ZM4.66683 2.12028C2.94683 2.12028 1.8335 3.23361 1.8335 4.95361V8.95365C1.8335 10.6737 2.94683 11.787 4.66683 11.787C4.94016 11.787 5.16683 12.0137 5.16683 12.287V13.707C5.16683 13.7937 5.22017 13.8336 5.2535 13.8536C5.28683 13.8736 5.35351 13.8936 5.42684 13.847L8.39351 11.8737C8.47351 11.8203 8.5735 11.787 8.6735 11.787H11.3402C13.0602 11.787 14.1735 10.6737 14.1735 8.95365V4.95361C14.1735 3.23361 13.0602 2.12028 11.3402 2.12028H4.66683V2.12028Z"
        fill={color}
      />
      <path
        d="M7.99972 8.07324C7.72638 8.07324 7.49972 7.84657 7.49972 7.57324V7.43327C7.49972 6.65993 8.06637 6.27993 8.2797 6.13326C8.52637 5.96659 8.60636 5.85326 8.60636 5.67993C8.60636 5.3466 8.33305 5.07324 7.99972 5.07324C7.66638 5.07324 7.39307 5.3466 7.39307 5.67993C7.39307 5.95326 7.1664 6.17993 6.89307 6.17993C6.61973 6.17993 6.39307 5.95326 6.39307 5.67993C6.39307 4.79326 7.11305 4.07324 7.99972 4.07324C8.88638 4.07324 9.60636 4.79326 9.60636 5.67993C9.60636 6.43993 9.04639 6.81992 8.83972 6.95992C8.57972 7.13325 8.49972 7.2466 8.49972 7.43327V7.57324C8.49972 7.85324 8.27305 8.07324 7.99972 8.07324Z"
        fill={color}
      />
      <path
        d="M8 9.7334C7.72 9.7334 7.5 9.50673 7.5 9.2334C7.5 8.96007 7.72667 8.7334 8 8.7334C8.27333 8.7334 8.5 8.96007 8.5 9.2334C8.5 9.50673 8.28 9.7334 8 9.7334Z"
        fill={color}
      />
    </svg>
  );
};
