import React from "react";
import { classesOf } from "../../utils";
import { SVGprops } from "../../interfaces";

export const LogoutIcon = (props: SVGprops) => {
  const {
    size,
    color = "currentColor",
    fill = "none",
    direction = "up",
  } = props;

  const arrowClasses = classesOf(
    "origin-center",
    direction === "down" && "rotate-180",
    direction === "right" && "rotate-90",
    direction === "left" && "-rotate-90",
  );

  return (
    <svg
      className={arrowClasses}
      width={size.toString()}
      height={size.toString()}
      viewBox="0 0 16 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.38342 2.83842C6.85648 3.26599 6.52844 3.95769 6.43154 5.08305C6.40784 5.35818 6.16561 5.562 5.89048 5.53831C5.61536 5.51462 5.41153 5.27238 5.43522 4.99726C5.54498 3.72262 5.93695 2.72432 6.75334 2.06189C7.55593 1.41066 8.67759 1.16016 10.0734 1.16016H10.16C11.6995 1.16016 12.9073 1.46535 13.7178 2.27577C14.5282 3.08619 14.8334 4.29404 14.8334 5.83349V10.1802C14.8334 11.7196 14.5282 12.9275 13.7178 13.7379C12.9073 14.5483 11.6995 14.8535 10.16 14.8535H10.0734C8.68773 14.8535 7.57282 14.6064 6.77186 13.9656C5.95646 13.3133 5.55932 12.3295 5.4422 11.0732C5.41657 10.7983 5.61869 10.5546 5.89364 10.529C6.16859 10.5034 6.41226 10.7055 6.43789 10.9804C6.54077 12.0841 6.8703 12.7637 7.39656 13.1847C7.93727 13.6173 8.77902 13.8535 10.0734 13.8535H10.16C11.6006 13.8535 12.4794 13.562 13.0107 13.0308C13.5419 12.4995 13.8334 11.6207 13.8334 10.1802V5.83349C13.8334 4.39294 13.5419 3.51412 13.0107 2.98288C12.4794 2.45163 11.6006 2.16016 10.16 2.16016H10.0734C8.76916 2.16016 7.92416 2.39965 7.38342 2.83842Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.91333 8C1.91333 7.72386 2.13719 7.5 2.41333 7.5H10C10.2761 7.5 10.5 7.72386 10.5 8C10.5 8.27614 10.2761 8.5 10 8.5H2.41333C2.13719 8.5 1.91333 8.27614 1.91333 8Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25351 5.41305C4.44878 5.60831 4.44878 5.92489 4.25351 6.12015L2.37373 7.99994L4.25351 9.87972C4.44878 10.075 4.44878 10.3916 4.25351 10.5868C4.05825 10.7821 3.74167 10.7821 3.54641 10.5868L1.31307 8.35349C1.11781 8.15823 1.11781 7.84164 1.31307 7.64638L3.54641 5.41305C3.74167 5.21779 4.05825 5.21779 4.25351 5.41305Z"
        fill={color}
      />
    </svg>
  );
};
