import React from "react";
import { SVGprops } from "../../interfaces";

export const StarIcon = (props: SVGprops) => {
  const { size, fill = "currentColor", iconStyle = "outline" } = props;

  return (
    <svg
      width={size.toString()}
      height={size.toString()}
      viewBox="0 0 25 24"
      fill="#000000"
      xmlns="http://www.w3.org/2000/svg"
    >
      {iconStyle === "outline" ? (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.21412 2.07295C10.1122 -0.690978 14.0224 -0.690988 14.9205 2.07295L16.1615 5.89261C16.2954 6.30463 16.6794 6.58359 17.1126 6.58359H21.1288C24.035 6.58359 25.2433 10.3024 22.8922 12.0106L19.643 14.3713C19.2925 14.626 19.1458 15.0773 19.2797 15.4894L20.5208 19.309C21.4188 22.0729 18.2554 24.3713 15.9043 22.6631L12.6551 20.3024C12.3046 20.0478 11.83 20.0478 11.4795 20.3024L8.23031 22.6631C5.87917 24.3713 2.71572 22.073 3.61378 19.309L4.85486 15.4894C4.98874 15.0773 4.84208 14.626 4.49159 14.3713L1.24239 12.0106C-1.10875 10.3024 0.0995789 6.58359 3.00575 6.58359H7.02198C7.4552 6.58359 7.83916 6.30463 7.97303 5.89261L9.21412 2.07295ZM13.0183 2.69098C12.719 1.76967 11.4156 1.76967 11.1162 2.69098L9.87515 6.51064C9.47352 7.74671 8.32166 8.58359 7.02198 8.58359H3.00575C2.03703 8.58359 1.63425 9.82321 2.41797 10.3926L5.66716 12.7533C6.71862 13.5172 7.1586 14.8713 6.75697 16.1074L5.51589 19.9271C5.21654 20.8484 6.27102 21.6145 7.05474 21.0451L10.3039 18.6844C11.3554 17.9205 12.7792 17.9205 13.8306 18.6844L17.0798 21.0451C17.8635 21.6145 18.918 20.8484 18.6187 19.927L17.3776 16.1074C16.976 14.8713 17.416 13.5172 18.4674 12.7533L21.7166 10.3926C22.5003 9.82321 22.0975 8.58359 21.1288 8.58359H17.1126C15.8129 8.58359 14.661 7.74671 14.2594 6.51064L13.0183 2.69098Z"
          fill={fill}
        />
      ) : (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.21412 2.07295C10.1122 -0.690979 14.0224 -0.690988 14.9205 2.07295L16.1615 5.89261C16.2954 6.30463 16.6794 6.58359 17.1126 6.58359H21.1288C24.035 6.58359 25.2433 10.3024 22.8922 12.0106L19.643 14.3713C19.2925 14.626 19.1458 15.0773 19.2797 15.4894L20.5208 19.309C21.4188 22.0729 18.2554 24.3713 15.9043 22.6631L12.6551 20.3024C12.3046 20.0478 11.83 20.0478 11.4795 20.3024L8.23031 22.6631C5.87917 24.3713 2.71572 22.073 3.61378 19.309L4.85486 15.4894C4.98874 15.0773 4.84208 14.626 4.49159 14.3713L1.24239 12.0106C-1.10875 10.3024 0.0995788 6.58359 3.00575 6.58359H7.02198C7.4552 6.58359 7.83916 6.30463 7.97303 5.89261L9.21412 2.07295Z"
          fill={fill}
        />
      )}
    </svg>
  );
};
