import React from "react";
import { LOCALES } from "../../common/constants";

interface ILanguageIcon {
  language: keyof typeof LOCALES;
}

const russian = (
  <>
    <g clipPath="url(#clip0_2576_11874)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.241211 0.369385H15.7585V12.1307H0.241211V0.369385Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.241211 4.28979H15.7585V12.1307H0.241211V4.28979Z"
        fill="#0039A6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.241211 8.21021H15.7585V12.1307H0.241211V8.21021Z"
        fill="#D52B1E"
      />
    </g>
    <defs>
      <clipPath id="clip0_2576_11874">
        <rect x="0.5" y="0.5" width="15" height="11.5" rx="4" fill="white" />
      </clipPath>
    </defs>
  </>
);

const english = (
  <>
    <g clipPath="url(#clip0_2576_1134)">
      <path
        d="M0.241211 2.36938L15.7585 2.36938V14.1307H0.241211L0.241211 2.36938Z"
        fill="#012169"
      />
      <path
        d="M2.05964 2.36938L7.97558 6.8044L13.8673 2.36938H15.7585V3.88856L9.93949 8.27457L15.7585 12.6361V14.1307H13.8188L7.99983 9.74474L2.20511 14.1307H0.241211L0.241211 12.6606L6.03593 8.29907L0.241211 3.93757L0.241211 2.36938H2.05964Z"
        fill="white"
      />
      <path
        d="M10.5214 9.25468L15.7585 13.1506V14.1307L9.18787 9.25468H10.5214ZM6.06018 9.74474L6.20565 10.6023L1.55048 14.1307H0.241211L6.06018 9.74474ZM15.7585 2.36938V2.44289L9.72127 7.04943L9.76977 5.9713L14.5462 2.36938H15.7585ZM0.241211 2.36938L6.03593 6.68188H4.58119L0.241211 3.3985L0.241211 2.36938Z"
        fill="#C8102E"
      />
      <path
        d="M6.08442 2.36938L6.08442 14.1307H9.96373V2.36938H6.08442ZM0.241211 6.28984L0.241211 10.2103H15.7585V6.28984L0.241211 6.28984Z"
        fill="white"
      />
      <path
        d="M0.241211 7.09843L0.241211 9.45071H15.7585V7.09843L0.241211 7.09843ZM6.86028 2.36938L6.86028 14.1307H9.18787V2.36938H6.86028Z"
        fill="#C8102E"
      />
    </g>
    <defs>
      <clipPath id="clip0_2576_1134">
        <rect x="0.5" y="2.5" width="15" height="11.5" rx="4" fill="white" />
      </clipPath>
    </defs>
  </>
);

export const chinese = (
  <>
    <rect x="0.5" y="2.5" width="15" height="11.5" rx="4" fill="#FF4B55" />
    <path
      d="M3.6269 4.9767L3.92863 5.88137L4.88227 5.88875C5.00575 5.88969 5.05694 6.04729 4.9576 6.1206L4.19045 6.68709L4.47809 7.59635C4.5153 7.71409 4.38129 7.81142 4.28083 7.73965L3.50501 7.18507L2.72919 7.73961C2.62873 7.81142 2.49472 7.71402 2.53193 7.59632L2.81957 6.68706L2.05242 6.1206C1.95308 6.04725 2.00427 5.88969 2.12774 5.88875L3.08139 5.88137L3.38311 4.9767C3.42219 4.85953 3.58786 4.85953 3.6269 4.9767Z"
      fill="#FFE15A"
    />
    <path
      d="M7.08987 5.19962L7.30623 5.49468L7.65539 5.38548C7.7006 5.37135 7.73778 5.4229 7.7101 5.4613L7.49635 5.75819L7.70809 6.05651C7.73552 6.09512 7.69798 6.14642 7.65288 6.13197L7.30444 6.02044L7.08614 6.31403C7.05789 6.35203 6.99748 6.33217 6.9973 6.28484L6.99569 5.91898L6.64901 5.80207C6.60416 5.7869 6.60434 5.72337 6.64933 5.70853L6.99673 5.59395L7.00082 5.22812C7.00132 5.18084 7.06187 5.16144 7.08987 5.19962Z"
      fill="#FFE15A"
    />
    <path
      d="M5.77253 4.32019L6.13624 4.3597L6.29141 4.02839C6.31152 3.98552 6.37462 3.99287 6.3843 4.03922L6.45908 4.39731L6.82211 4.44248C6.86911 4.44832 6.88163 4.51063 6.84051 4.53419L6.52304 4.71595L6.59227 5.07518C6.60123 5.12172 6.54584 5.15284 6.51075 5.12104L6.23975 4.87528L5.91952 5.05209C5.87808 5.075 5.83129 5.03191 5.85072 4.98871L6.00069 4.655L5.73352 4.40505C5.69896 4.37285 5.72546 4.3151 5.77253 4.32019Z"
      fill="#FFE15A"
    />
    <path
      d="M6.34751 7.86561L6.13115 8.16067L5.78199 8.05147C5.73678 8.03734 5.6996 8.0889 5.72728 8.12729L5.94103 8.42418L5.72929 8.7225C5.70186 8.76111 5.7394 8.81241 5.7845 8.79796L6.13294 8.68643L6.35124 8.98002C6.37949 9.01802 6.4399 8.99816 6.44008 8.95083L6.44169 8.58497L6.78837 8.46806C6.83326 8.4529 6.83304 8.38937 6.78805 8.37452L6.44065 8.25994L6.43656 7.89412C6.43599 7.84686 6.37551 7.8274 6.34751 7.86561Z"
      fill="#FFE15A"
    />
    <path
      d="M7.66489 7.03924L7.30117 7.07875L7.14601 6.74745C7.1259 6.70457 7.0628 6.71192 7.05312 6.75828L6.9783 7.11636L6.61527 7.16153C6.56827 7.16738 6.55575 7.22969 6.59688 7.25324L6.91434 7.43501L6.84511 7.79424C6.83615 7.84077 6.89154 7.87189 6.92664 7.84009L7.19764 7.59433L7.51786 7.77115C7.5593 7.79406 7.60609 7.75096 7.58666 7.70776L7.43669 7.37406L7.70386 7.1241C7.73852 7.09191 7.71203 7.03415 7.66489 7.03924Z"
      fill="#FFE15A"
    />
  </>
);

const languageIconMap = {
  ru: russian,
  en: english,
  cn: chinese,
};

export const LanguageIcon = (props: ILanguageIcon) => {
  const { language } = props;
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 -1 16 16"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      {languageIconMap[language]}
    </svg>
  );
};
