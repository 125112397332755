import React from "react";

export const Logo = () => (
  <svg
    width="68"
    height="24"
    viewBox="0 0 68 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.1692 19.707C27.1604 19.707 26.2745 19.4874 25.5117 19.0482C24.749 18.609 24.1523 17.9997 23.7216 17.2202C23.2972 16.4408 23.085 15.5438 23.085 14.5293C23.085 13.4344 23.2941 12.4848 23.7124 11.6806C24.1307 10.8703 24.712 10.2424 25.4564 9.797C26.2007 9.35161 27.0619 9.12891 28.04 9.12891C29.0735 9.12891 29.9501 9.37326 30.6698 9.86195C31.3957 10.3445 31.934 11.028 32.2846 11.9126C32.6353 12.7972 32.7675 13.8396 32.6814 15.0396H30.4761V14.2231C30.4699 13.1344 30.2792 12.3395 29.904 11.8384C29.5287 11.3373 28.9382 11.0868 28.1323 11.0868C27.2219 11.0868 26.5452 11.3713 26.1023 11.9405C25.6594 12.5034 25.4379 13.3292 25.4379 14.418C25.4379 15.4325 25.6594 16.2181 26.1023 16.7748C26.5452 17.3316 27.1911 17.6099 28.04 17.6099C28.5875 17.6099 29.0581 17.4893 29.4518 17.2481C29.8517 17.0006 30.1593 16.6449 30.3746 16.181L32.5707 16.8491C32.1893 17.7522 31.5987 18.4543 30.799 18.9554C30.0055 19.4565 29.1289 19.707 28.1692 19.707ZM24.7367 15.0396V13.3509H31.5926V15.0396H24.7367Z"
      fill="white"
    />
    <path
      d="M42.2855 19.4286V14.6035C42.2855 14.288 42.2639 13.9385 42.2209 13.555C42.1778 13.1715 42.0763 12.8034 41.9164 12.4508C41.7626 12.092 41.5288 11.7982 41.2151 11.5693C40.9075 11.3404 40.4892 11.226 39.9602 11.226C39.6772 11.226 39.3973 11.2724 39.1205 11.3652C38.8437 11.4579 38.5914 11.6188 38.3638 11.8477C38.1424 12.0704 37.964 12.3797 37.8287 12.7756C37.6933 13.1653 37.6257 13.6664 37.6257 14.2788L36.3061 13.7127C36.3061 12.8591 36.4692 12.0858 36.7952 11.393C37.1274 10.7002 37.6133 10.1496 38.2531 9.74132C38.8929 9.32686 39.6803 9.11963 40.6153 9.11963C41.3535 9.11963 41.9625 9.24335 42.4423 9.49079C42.9221 9.73823 43.3035 10.0537 43.5865 10.4373C43.8695 10.8208 44.0786 11.2291 44.214 11.6621C44.3493 12.0951 44.4354 12.5065 44.4723 12.8962C44.5154 13.2797 44.5369 13.5921 44.5369 13.8334V19.4286H42.2855ZM35.3742 19.4286V9.40728H37.3581V12.5158H37.6257V19.4286H35.3742Z"
      fill="white"
    />
    <path
      d="M52.033 19.707C51.0057 19.707 50.1291 19.4781 49.4032 19.0204C48.6773 18.5564 48.1206 17.9254 47.733 17.1274C47.3516 16.3294 47.1579 15.4263 47.1517 14.418C47.1579 13.3911 47.3578 12.4817 47.7515 11.6899C48.1513 10.8919 48.7173 10.2671 49.4493 9.81556C50.1814 9.35779 51.0518 9.12891 52.0607 9.12891C53.1926 9.12891 54.1491 9.41656 54.9304 9.99186C55.7178 10.561 56.2314 11.3404 56.4713 12.3302L54.2568 12.9333C54.0845 12.3951 53.7985 11.9776 53.3986 11.6806C52.9988 11.3775 52.5436 11.226 52.033 11.226C51.4547 11.226 50.978 11.3652 50.6027 11.6435C50.2275 11.9157 49.9507 12.2931 49.7723 12.7756C49.5939 13.2581 49.5047 13.8055 49.5047 14.418C49.5047 15.3706 49.7169 16.1408 50.1414 16.7284C50.5658 17.3161 51.1964 17.6099 52.033 17.6099C52.6235 17.6099 53.088 17.4738 53.4263 17.2017C53.7708 16.9295 54.0292 16.5367 54.2014 16.0232L56.4713 16.5336C56.1637 17.5543 55.6255 18.3399 54.8565 18.8904C54.0876 19.4348 53.1464 19.707 52.033 19.707Z"
      fill="white"
    />
    <path
      d="M60.193 23.8826L62.1123 18.5842L62.1492 20.1431L57.8031 9.40728H60.1192L63.2011 17.3501H62.6105L65.6094 9.40728H67.8332L62.2783 23.8826H60.193Z"
      fill="white"
    />
    <path
      d="M0.155273 19.4287V6.06689H8.73669V8.16395H2.37906V11.458H7.62941V13.5551H2.37906V17.3316H8.73669V19.4287H0.155273Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.6797 1.73291L15.874 19.5141L9.98438 14.776L11.9639 12.2878L15.0453 14.7667L23.9758 0.0712891L26.6797 1.73291Z"
      fill="#0DB77A"
    />
  </svg>
);
