import React from "react";
import { classesOf } from "../../utils";
import { SVGprops } from "../../interfaces";

export const MembersIcon = (props: SVGprops) => {
  const {
    size,
    color = "currentColor",
    fill = "none",
    direction = "up",
  } = props;

  const arrowClasses = classesOf(
    "origin-center",
    direction === "down" && "rotate-180",
    direction === "right" && "rotate-90",
    direction === "left" && "-rotate-90",
  );

  return (
    <svg
      className={arrowClasses}
      width={size.toString()}
      height={size.toString()}
      viewBox="0 0 16 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.94 1.8335C11.2628 1.8335 10.72 2.37631 10.72 3.0535C10.72 3.70544 11.2214 4.23088 11.8561 4.27204C11.9094 4.26732 11.9639 4.26732 12.0171 4.27204C12.6508 4.23091 13.1544 3.70616 13.16 3.05171C13.159 2.38074 12.6153 1.8335 11.94 1.8335ZM9.71997 3.0535C9.71997 1.82402 10.7105 0.833496 11.94 0.833496C13.1707 0.833496 14.16 1.83195 14.16 3.0535V3.05707H14.16C14.1514 4.25635 13.2074 5.23008 12.0181 5.27317C11.9909 5.27416 11.9636 5.27292 11.9366 5.26947C11.9096 5.27292 11.8824 5.27416 11.8552 5.27317C10.6665 5.2301 9.71997 4.25618 9.71997 3.0535Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.207 5.90099C12.2206 5.72343 13.3763 5.89271 14.2164 6.4502L14.2173 6.4508C14.7916 6.83364 15.145 7.38838 15.145 8.00683C15.145 8.62527 14.7916 9.18001 14.2173 9.56285C13.3834 10.121 12.2415 10.2897 11.2305 10.1199C10.9582 10.0742 10.7745 9.81638 10.8202 9.54404C10.8659 9.27171 11.1237 9.08801 11.3961 9.13373C12.2114 9.2706 13.0824 9.11944 13.6617 8.73141L13.6626 8.7308C14.0284 8.48697 14.145 8.21505 14.145 8.00683C14.145 7.7987 14.0285 7.52691 13.6631 7.28316C13.0766 6.8942 12.1925 6.74358 11.3796 6.88599C11.1076 6.93364 10.8484 6.75177 10.8008 6.47977C10.7531 6.20777 10.935 5.94864 11.207 5.90099Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.81995 3.0535C1.81995 1.83195 2.80921 0.833496 4.03995 0.833496C5.26942 0.833496 6.25995 1.82402 6.25995 3.0535C6.25995 4.25618 5.31338 5.2301 4.12472 5.27317C4.09751 5.27416 4.07027 5.27292 4.04328 5.26947C4.01629 5.27292 3.98906 5.27416 3.96184 5.27317C2.77255 5.23008 1.82853 4.25635 1.81996 3.05707L1.81995 3.0535ZM2.81995 3.05172C2.82553 3.70616 3.32913 4.23091 3.96278 4.27204C4.01602 4.26732 4.07055 4.26732 4.12379 4.27204C4.75852 4.23088 5.25995 3.70544 5.25995 3.0535C5.25995 2.37631 4.71714 1.8335 4.03995 1.8335C3.36462 1.8335 2.82091 2.38074 2.81995 3.05172Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.31686 7.28315C2.90336 6.8942 3.78743 6.74358 4.60035 6.88599C4.87235 6.93364 5.13148 6.75177 5.17913 6.47977C5.22678 6.20777 5.0449 5.94864 4.7729 5.90099C3.75936 5.72343 2.60367 5.89271 1.76351 6.4502L1.76351 6.4502L1.76261 6.4508C1.18835 6.83364 0.834961 7.38838 0.834961 8.00683C0.834961 8.62511 1.18817 9.17972 1.76216 9.56255C2.59614 10.1209 3.73823 10.2897 4.74941 10.1199C5.02174 10.0742 5.20545 9.81637 5.15973 9.54404C5.11401 9.27171 4.85617 9.08801 4.58384 9.13373C3.76853 9.2706 2.89753 9.11944 2.31822 8.7314L2.31731 8.7308C1.95157 8.48697 1.83496 8.21505 1.83496 8.00683C1.83496 7.79869 1.95147 7.52691 2.31686 7.28315Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.93997 6.81348C7.26278 6.81348 6.71997 7.35629 6.71997 8.03348C6.71997 8.68543 7.22139 9.21086 7.85613 9.25202C7.91012 9.24723 7.96543 9.2473 8.01938 9.25223C8.64789 9.21619 9.15436 8.68967 9.15997 8.03169C9.15901 7.36072 8.6153 6.81348 7.93997 6.81348ZM5.71997 8.03348C5.71997 6.804 6.71049 5.81348 7.93997 5.81348C9.17071 5.81348 10.16 6.81193 10.16 8.03348V8.03705H10.16C10.1514 9.23311 9.20979 10.2186 8.01446 10.2533C7.98845 10.254 7.96243 10.2527 7.93664 10.2495C7.90965 10.2529 7.88241 10.2541 7.8552 10.2532C6.66654 10.2101 5.71997 9.23616 5.71997 8.03348Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00248 10.8232C8.78148 10.8232 9.58736 11.0172 10.2173 11.4372C10.7916 11.8201 11.145 12.3748 11.145 12.9932C11.145 13.6116 10.7917 14.1663 10.2176 14.5491C9.58492 14.9721 8.77932 15.1682 7.99998 15.1682C7.22065 15.1682 6.41505 14.9721 5.78241 14.5491C5.20828 14.1663 4.85498 13.6116 4.85498 12.9932C4.85498 12.3748 5.20837 11.8201 5.78263 11.4372L5.78383 11.4364L5.78383 11.4364C6.41649 11.0173 7.22334 10.8232 8.00248 10.8232ZM6.33674 12.2697C5.97146 12.5134 5.85498 12.7851 5.85498 12.9932C5.85498 13.2015 5.97159 13.4734 6.33733 13.7172L6.33793 13.7176C6.7719 14.0078 7.3728 14.1682 7.99998 14.1682C8.62716 14.1682 9.22806 14.0078 9.66203 13.7176L9.66263 13.7172C10.0284 13.4734 10.145 13.2015 10.145 12.9932C10.145 12.785 10.0284 12.5131 9.66263 12.2693C9.2326 11.9826 8.63181 11.8232 8.00248 11.8232C7.37359 11.8232 6.77071 11.9824 6.33674 12.2697Z"
        fill={color}
      />
    </svg>
  );
};
