import React from "react";
import { SVGprops } from "../../interfaces";

export const CopyIcon = (props: SVGprops) => {
  const { size, color = "currentColor", fill = "none" } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.29175 4.75008C6.29175 3.35339 6.57491 2.5383 7.05661 2.05661C7.5383 1.57491 8.35339 1.29175 9.75008 1.29175H13.2501C14.6468 1.29175 15.4619 1.57491 15.9436 2.05661C16.4252 2.5383 16.7084 3.35339 16.7084 4.75008V8.25008C16.7084 9.64677 16.4252 10.4619 15.9436 10.9436C15.4619 11.4252 14.6468 11.7084 13.2501 11.7084H12.9584V9.75008C12.9584 8.2301 12.6582 7.00353 11.8274 6.17272C10.9966 5.34192 9.77006 5.04175 8.25008 5.04175H6.29175V4.75008ZM5.04175 5.04175V4.75008C5.04175 3.2301 5.34192 2.00353 6.17272 1.17272C7.00353 0.341916 8.2301 0.041748 9.75008 0.041748H13.2501C14.7701 0.041748 15.9966 0.341916 16.8274 1.17272C17.6582 2.00353 17.9584 3.2301 17.9584 4.75008V8.25008C17.9584 9.77006 17.6582 10.9966 16.8274 11.8274C15.9966 12.6582 14.7701 12.9584 13.2501 12.9584H12.9584V13.2501C12.9584 14.7701 12.6582 15.9966 11.8274 16.8274C10.9966 17.6582 9.77006 17.9584 8.25008 17.9584H4.75008C3.2301 17.9584 2.00353 17.6582 1.17272 16.8274C0.341916 15.9966 0.041748 14.7701 0.041748 13.2501V9.75008C0.041748 8.2301 0.341916 7.00353 1.17272 6.17272C2.00353 5.34192 3.2301 5.04175 4.75008 5.04175H5.04175ZM5.66675 6.29175H4.75008C3.35339 6.29175 2.5383 6.57491 2.05661 7.05661C1.57491 7.5383 1.29175 8.35339 1.29175 9.75008V13.2501C1.29175 14.6468 1.57491 15.4619 2.05661 15.9436C2.5383 16.4252 3.35339 16.7084 4.75008 16.7084H8.25008C9.64677 16.7084 10.4619 16.4252 10.9436 15.9436C11.4252 15.4619 11.7084 14.6468 11.7084 13.2501V12.3334V9.75008C11.7084 8.70256 11.5491 7.98219 11.2663 7.48427C11.172 7.31829 11.064 7.17703 10.9436 7.05661C10.4619 6.57491 9.64677 6.29175 8.25008 6.29175H5.66675Z"
        fill={color}
      />
    </svg>
  );
};
